import React from "react";

function Sets() {
    return (
        <div className="detail-box">

<h3 align="center">Sets</h3>

<div className="home-category">FiGPiN Exclusive Box Sets</div>
<ul className="gallery mw-gallery-traditional">

<li className="gallerybox">
        <div className="setbox">
        <a title="Powerpuff Girls" href="/set/Powerpuff%20Girls%20Box%20Set">
        <img alt="Powerpuff Girls" className="box-image" src="box_ppg.png"/></a>
        <div className="pinname"><b>Powerpuff Girls</b></div>
    </div></li>

<li className="gallerybox">
        <div className="setbox">
        <a title="Naruto Shippuden" href="/set/Naruto%20Shippuden%20Box%20Set">
        <img alt="Naruto Shippuden" className="box-image" src="box_naruto24.png"/></a>
        <div className="pinname"><b>Naruto Shippuden</b></div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Hall of Armor Blueprint" href="/set/Hall%20of%20Armor%20Blueprint%20Box%20Set">
        <img alt="Hall of Armor Blueprint" className="box-image" src="box_armor_blueprint.png"/></a>
        <div className="pinname"><b>Hall of Armor Blueprint</b></div>
    </div></li>

<li className="gallerybox">
        <div className="setbox">
        <a title="Hall of Armor" href="/set/Hall%20of%20Armor%20Box%20Set">
        <img alt="Hall of Armor" className="box-image" src="box_armor.png"/></a>
        <div className="pinname"><b>Hall of Armor</b></div>
    </div></li>

<li className="gallerybox">
        <div className="setbox">
        <a title="Power Rangers" href="/set/Power%20Rangers%20Box%20Set">
        <img alt="Power Rangers" className="box-image" src="box_pr.png"/></a>
        <div className="pinname"><b>Power Rangers</b></div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="X-Men '97" href="/set/X-Men%20Box%20Set">
        <img alt="X-Men '97" className="box-image" src="box_xmen.png"/></a>
        <div className="pinname"><b>X-Men '97</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Yu-Gi-Oh" href="/set/Yu-Gi-Oh%20Box%20Set">
        <img alt="Yu-Gi-Oh" className="box-image" src="box_yugioh.png"/></a>
        <div className="pinname"><b>Yu-Gi-Oh</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Attack on Titan" href="/set/Attack%20on%20Titan%20Box%20Set">
        <img alt="Attack on Titan" className="box-image" src="box_aot.png"/></a>
        <div className="pinname"><b>Attack on Titan</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>

<li className="gallerybox">
        <div className="setbox">
        <a title="Avengers" href="/set/Avengers%20Box%20Set">
        <img alt="Avengers" className="box-image" src="box_avengers.png"/></a>
        <div className="pinname"><b>Avengers</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Winnie the Pooh" href="/set/Winnie%20the%20Pooh%20Box%20Set">
        <img alt="Winnie the Pooh" className="box-image" src="box_pooh.png"/></a>
        <div className="pinname"><b>Winnie the Pooh</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Assassin's Creed" href="/set/Assassin's%20Creed%20Box%20Set">
        <img alt="Assassin's Creed" className="box-image" src="box_ac.png"/></a>
        <div className="pinname"><b>Assassin's Creed</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Sailor Moon" href="/set/Sailor%20Moon%20Box%20Set">
        <img alt="Sailor Moon" className="box-image" src="box_sailormoon.png"/></a>
        <div className="pinname"><b>Sailor Moon</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="One Piece" href="/set/One%20Piece%20Box%20Set">
        <img alt="One Piece" className="box-image" src="box_onepiece.png"/></a>
        <div className="pinname"><b>One Piece</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Villains 2022" href="/set/Villains%202022%20Box%20Set">
        <img alt="Villains 2022" className="box-image" src="box_villains_2022.png"/></a>
        <div className="pinname"><b>Villains 2022</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Batman" href="/set/Batman%20Box%20Set">
        <img alt="Batman" className="box-image" src="box_batman.png"/></a>
        <div className="pinname"><b>Batman</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
    <li className="gallerybox">
        <div className="setbox">
        <a title="Villains 2021" href="/set/Villains%202021%20Box%20Set">
        <img alt="Villains 2021" className="box-image" src="box_villains.png"/></a>
        <div className="pinname"><b>Villains 2021</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
    <li className="gallerybox">
        <div className="setbox">
        <a title="Disney Princess 2021" href="/set/Princess%202021%20Box%20Set">
        <img alt="Disney Princess 2021" className="box-image" src="box_princess2021.png"/></a>
        <div className="pinname"><b>Princess 2021</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
    <li className="gallerybox">
        <div className="setbox">
        <a title="Stranger Things" href="/set/Stranger%20Things%20Box%20Set">
        <img alt="Stranger Things" className="box-image" src="box_stranger.png"/></a>
        <div className="pinname"><b>Stranger Things</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>

    <li className="gallerybox">
        <div className="setbox">
        <a title="The Mandalorian" href="/set/The%20Mandalorian%20Box%20Set">
        <img alt="The Mandalorian" className="box-image" src="box_mando.png"/></a>
        <div className="pinname"><b>The Mandalorian</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 50</div>
    </div></li>
    <li className="gallerybox">
        <div className="setbox">
        <a title="FiGPiN.KiNGPiN Instagram" href="/set/My%20Hero%20Academia%20Box%20Set">
        <img alt="FiGPiN.KiNGPiN Instagram" className="box-image" src="box_mha.png"/></a>
        <div className="pinname"><b>My Hero Academia</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
    <li className="gallerybox">
        <div className="setbox">
        <a title="Spider-Verse 7" href="/set/Into%20The%20Spider-Verse%20Box%20Set%20-%20Black">
        <img alt="Spider-Verse 7" className="box-image" src="box_spiderverse7.png"/></a>
        <div className="pinname"><b>Into The Spider-Verse - Black</b><br/>Deluxe Box Set Bonus: 10<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Disney Princess 2019" href="/set/Princess%202019%20Box%20Set">
        <img alt="Disney Princess 2019" className="box-image" src="box_princess2019.png"/></a>
        <div className="pinname"><b>Princess 2019</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>
    <li className="gallerybox">
        <div className="setbox">
        <a title="Spider-Verse 5" href="/set/Into%20The%20Spider-Verse%20Box%20Set%20-%20Silver">
        <img alt="Spider-Verse 5" className="box-image" src="box_spiderverse5.png"/></a>
        <div className="pinname"><b>Into The Spider-Verse (Silver)</b><br/>Deluxe Box Set Bonus: 15<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>

</ul>

<div className="home-category">Retailer Exclusive Box Sets</div>

<ul className="gallery mw-gallery-traditional">
<li className="gallerybox">
            <div className="setbox">
            <a title="Cowboy Bebop" href="/set/Cowboy%20Bebop%20Box%20Set">
            <img alt="Cowboy Bebop" className="box-image" src="box_cowboy_bebop.png"/></a>
            <div className="pinname"><b>Cowboy Bebop</b></div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="AMC Clip" href="/set/AMC%20Clip%20Box%20Set">
            <img alt="AMC Clip" className="box-image" src="box_amc.png"/></a>
            <div className="pinname"><b>AMC Clip</b></div>
        </div></li>  
<li className="gallerybox">
            <div className="setbox">
            <a title="D100 QVC" href="/set/D100%20QVC%20Box%20Set">
            <img alt="D100 QVC" className="box-image" src="box_d100qvc.png"/></a>
            <div className="pinname"><b>Disney 100 QVC</b></div>
        </div></li>  
<li className="gallerybox">
        <div className="setbox">
        <a title="WB 100 Looney Tunes" href="/set/WB%20100%20Looney%20Tunes%20Box%20Set">
        <img alt="WB 100 Looney Tunes" className="box-image" src="box_wb100.png"/></a>
        <div className="pinname"><b>WB 100 Looney Tunes</b></div>
    </div></li>
    <li className="gallerybox">
        <div className="setbox">
        <a title="D100" href="/set/D100%20Box%20Set">
        <img alt="D100" className="box-image" src="box_d100.png"/></a>
        <div className="pinname"><b>Disney 100</b></div>
    </div></li>
    <li className="gallerybox">
        <div className="setbox">
        <a title="Across The Spider-Verse" href="/set/Across%20The%20Spider-Verse%20Box%20Set">
        <img alt="Across The Spider-Verse" className="box-image" src="box_acrossthespiderverse.png"/></a>
        <div className="pinname"><b>Across The Spider-Verse</b></div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Guardians of the Galaxy 3" href="/set/Guardians%20of%20the%20Galaxy%203%20Box%20Set">
        <img alt="Guardians of the Galaxy 3" className="box-image" src="box_gotg.png"/></a>
        <div className="pinname"><b>Guardians of the Galaxy 3</b></div>
    </div></li>
    <li className="gallerybox">
        <div className="setbox">
        <a title="Stranger Things (Greyscale)" href="/set/Stranger Things Greyscale Box Set">
        <img alt="Stranger Things (Greyscale)" className="box-image" src="box_strangerthings_greyscale.png"/></a>
        <div className="pinname"><b>Stranger Things (Greyscale)</b><br/>Box Set Bonus: 15</div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Squid Game" href="/set/Squid%20Game%20Box%20Set">
        <img alt="Squid Game" className="box-image" src="box_squidgame.png"/></a>
        <div className="pinname"><b>Squid Game</b><br/>Box Set Bonus: 10<br/>Reward Bonus: 20<br/>In-App Logo: L56</div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="KISS" href="/set/Kiss%20Box%20Set">
        <img alt="KISS" className="box-image" src="box_kiss.png"/></a>
        <div className="pinname"><b>KISS</b></div>
    </div></li> 
    <li className="gallerybox">
        <div className="setbox">
        <a title="Nightmare Before Christmas" href="/set/Nightmare%20Before%20Christmas%20Box%20Set">
        <img alt="Nightmare Before Christmas" className="box-image" src="box_nightmare.png"/></a>
        <div className="pinname"><b>Nightmare Before Christmas</b></div>
    </div></li> 
    <li className="gallerybox">
        <div className="setbox">
        <a title="Naruto" href="/set/Naruto%20Box%20Set">
        <img alt="Naruto" className="box-image" src="box_naruto.png"/></a>
        <div className="pinname"><b>Naruto</b></div>
    </div></li> 
<li className="gallerybox">
        <div className="setbox">
        <a title="Dragon Ball Z" href="/set/Dragon%20Ball%20Z%20Box%20Set">
        <img alt="Dragon Ball Z" className="box-image" src="box_dbz.png"/></a>
        <div className="pinname"><b>Dragon Ball Z</b><br/>Deluxe Box Set Bonus: 20<br/>Deluxe+ Box Set Bonus: 25</div>
    </div></li>  

</ul>

<div className="home-category">2-Packs</div>

<ul className="gallery mw-gallery-traditional">
<li className="gallerybox">
        <div className="setbox">
        <a title="Stitch & Angel" href="/set/Stitch%20and%20Angel%202-Pack">
        <img alt="Stitch & Angel" className="box-image" src="box_stitch_angel.png"/></a>
        <div className="pinname"><b>Stitch & Angel</b></div>
    </div></li>

<li className="gallerybox">
        <div className="setbox">
        <a title="Ellie & Carl" href="/set/Ellie%20and%20Carl%202-Pack">
        <img alt="Ellie & Carl" className="box-image" src="box_ellie_carl.png"/></a>
        <div className="pinname"><b>Ellie & Carl</b></div>
    </div></li>

<li className="gallerybox">
        <div className="setbox">
        <a title="Badtz-Maru & Megumi Fushiguro" href="/set/Badtz-Maru%20and%20Megumi%20Fushiguro%202-Pack">
        <img alt="Badtz-Maru & Megumi Fushiguro" className="box-image" src="box_badtzmaru_megumi.png"/></a>
        <div className="pinname"><b>Badtz-Maru & Megumi Fushiguro</b></div>
    </div></li>

<li className="gallerybox">
        <div className="setbox">
        <a title="Luna & Artemis" href="/set/Luna%20and%20Artemis%202-Pack">
        <img alt="Luna & Artemis" className="box-image" src="box_luna_artemis.png"/></a>
        <div className="pinname"><b>Luna & Artemis</b></div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Badtz-Maru & Levi" href="/set/Badtz-Maru%20and%20Levi%202-Pack">
        <img alt="Badtz-Maru & Levi" className="box-image" src="box_badtzmaru_levi.png"/></a>
        <div className="pinname"><b>Badtz-Maru & Levi</b></div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Lilo & Stitch" href="/set/Lilo%20and%20Stitch%202-Pack">
        <img alt="Lilo & Stitch" className="box-image" src="box_lilo_stitch.png"/></a>
        <div className="pinname"><b>Lilo & Stitch</b></div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Cinnamoroll & Satoru Gojo" href="/set/Cinnamoroll%20and%20Satoru%20Gojo%202-Pack">
        <img alt="Cinnamoroll & Satoru Gojo" className="box-image" src="box_cinnamoroll_satoru.png"/></a>
        <div className="pinname"><b>Cinnamoroll & Satoru Gojo</b></div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="My Melody & Nobara Kugisaki" href="/set/My%20Melody%20and%20Nobara%20Kugisaki%202-Pack">
        <img alt="My Melody & Nobara Kugisaki" className="box-image" src="box_mymelody_nobara.png"/></a>
        <div className="pinname"><b>My Melody & Nobara Kugisaki</b></div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Steve & Creeper" href="/set/Steve%20and%20Creeper%202-Pack">
        <img alt="Steve & Creeper" className="box-image" src="box_steve_creeper.png"/></a>
        <div className="pinname"><b>Steve & Creeper</b></div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Hello Kitty & Ryomen Sukuna" href="/set/Hello%20Kitty%20and%20Ryomen%20Sukuna%202-Pack">
        <img alt="Hello Kitty & Ryomen Sukuna" className="box-image" src="box_hk_ryomen.png"/></a>
        <div className="pinname"><b>Hello Kitty & Ryomen Sukuna</b></div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Pochacco & Yuji Itadori" href="/set/Pochacco%20and%20Yuji%20Itadori%202-Pack">
        <img alt="Pochacco & Yuji Itadori" className="box-image" src="box_pochacco_yuji.png"/></a>
        <div className="pinname"><b>Pochacco & Yuji Itadori</b></div>
    </div></li>
<li className="gallerybox">
        <div className="setbox">
        <a title="Sonic & Shadow" href="/set/Hawkeye%20and%20Black%20Widow%202-Pack">
        <img alt="Sonic & Shadow" className="box-image" src="box_hawkeye_black_widow.png"/></a>
        <div className="pinname"><b>Hawkeye & Black Widow</b></div>
    </div></li>
    
<li className="gallerybox">
        <div className="setbox">
        <a title="Sonic & Shadow" href="/set/Sonic%20and%20Shadow%202-Pack">
        <img alt="Sonic & Shadow" className="box-image" src="box_sonic_shadow.png"/></a>
        <div className="pinname"><b>Sonic & Shadow</b></div>
    </div></li>


<li className="gallerybox">
        <div className="setbox">
        <a title="Carl & Russell (Box Lunch Exclusive)" href="/set/Carl%20and%20Russell%202-Pack">
        <img alt="Carl & Russell (Box Lunch Exclusive)" className="box-image" src="box_carl_russell.png"/></a>
        <div className="pinname"><b>Carl & Russell (Glitter)</b><br/>2-Pack Set Bonus: 10</div>
    </div></li>
    <li className="gallerybox">
        <div className="setbox">
        <a title="FiGPiN Subreddit" href="/set/Mickey%20and%20Minnie%202-Pack%20-%20BW">
        <img alt="FiGPiN Subreddit" className="box-image" src="box_figpin_mickey_minnie.png"/></a>
        <div className="pinname"><b>Mickey & Minnie (Black & White)</b><br/>2-Pack Set Bonus: 10</div>
    </div></li>

    <li className="gallerybox">
        <div className="setbox">
        <a title="FiGPiN Watch Ranking Website" href="/set/Mickey%20and%20Minnie%202-Pack%20-%20Glitter">
        <img alt="FiGPiN Watch Ranking Website" className="box-image" src="box_eccc_mickey_minnie.png"/></a>
        <div className="pinname"><b>Mickey & Minnie (Glitter)</b><br/>2-Pack Set Bonus: 10</div>
    </div></li>
    <li className="gallerybox">
        <div className="setbox">
        <a title="Display Geek (Displays)" href="/set/Mickey%20and%20Minnie%202-Pack%20-%20Classic">
        <img alt="Display Geek (Displays)" className="box-image" src="box_d23_mickey_minnie.png"/></a>
        <div className="pinname"><b>Mickey & Minnie (Classic)</b><br/>2-Pack Set Bonus: 10</div>
    </div></li>
    <li className="gallerybox">
        <div className="setbox">
        <a title="Captain America & Red Skull (ECCC 2020 Exclusive)" href="/set/Captain%20America%20and%20Red%20Skull%202-Pack">
        <img alt="Captain America & Red Skull (ECCC 2020 Exclusive)" className="box-image" src="box_cap_skull.png"/></a>
        <div className="pinname"><b>Captain America & Red Skull</b><br/>2-Pack Set Bonus: 10</div>
    </div></li>

    <li className="gallerybox">
        <div className="setbox">
        <a title="Thor & Loki (NYCC 2019 Exclusive)" href="/set/Thor%20and%20Loki%202-Pack">
        <img alt="Thor & Loki (NYCC 2019 Exclusive)" className="box-image" src="box_thor_loki.png"/></a>
        <div className="pinname"><b>Thor & Loki</b><br/>2-Pack Set Bonus: 10</div>
    </div></li>

    <li className="gallerybox">
        <div className="setbox">
        <a title="Batman & The Joker (SDCC 2019 Exclusive)" href="/set/Batman%20and%20Joker%202-Pack">
        <img alt="Batman & The Joker (SDCC 2019 Exclusive)" className="box-image" src="box_batman_joker.png"/></a>
        <div className="pinname"><b>Batman & The Joker (Glitter)</b><br/>2-Pack Set Bonus: 10</div>
    </div></li>

    <li className="gallerybox">
        <div className="setbox">
        <a title="Deku * All Might" href="/set/Deku%20and%20All%20Might%202-Pack">
        <img alt="Deku * All Might" className="box-image" src="box_deku_allmight.png"/></a>
        <div className="pinname"><b>Deku & All Might</b></div>
    </div></li>

    <li className="gallerybox">
        <div className="setbox">
        <a title="SSGSS Goku & SSGSS Vegeta (ECCC 2019 & GameStop Exclusive)" href="/set/Goku%20and%20Vegeta%202-Pack">
        <img alt="SSGSS Goku & SSGSS Vegeta (ECCC 2019 & GameStop Exclusive)" className="box-image" src="box_goku_vegeta.png"/></a>
        <div className="pinname"><b>SSGSS Goku & SSGSS Vegeta</b><br/>2-Pack Set Bonus: 10</div>
    </div></li>
    
    <li className="gallerybox">
        <div className="setbox">
        <a title="Gogeta & Broly (Funimation Exclusive)" href="/set/Gogeta%20and%20Broly%202-Pack">
        <img alt="Gogeta & Broly (Funimation Exclusive)" className="box-image" src="box_gogeta_broly.png"/></a>
        <div className="pinname"><b>Gogeta & Broly (Gold)</b><br/>2-Pack Set Bonus: 10</div>
    </div></li>

      </ul>

      <div className="home-category">Mystery Mini Sets</div>
    <ul className="gallery mw-gallery-traditional">

    <li className="gallerybox">
            <div className="setbox">
            <a title="Snow White 1" href="/set/Snow%20White%20Mystery%20Series%201">
            <img alt="Snow White 1" className="box-image" src="box_snowwhite1.png"/></a>
            <div className="pinname"><b>Snow White Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y299</div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Nightmare Before Christmas 1" href="/set/The%20Nightmare%20Before%20Christmas%20Mystery%20Series%201">
            <img alt="Nightmare Before Christmas 1" className="box-image" src="box_nbc1.png"/></a>
            <div className="pinname"><b>Nightmare Before Christmas Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y355</div>
        </div></li>          
        <li className="gallerybox">
            <div className="setbox">
            <a title="Spider-Man Across The Spider-Verse 1" href="/set/Spider-Man:%20Across%20The%20Spider-Verse%20Mystery%20Series%201">
            <img alt="Spider-Man Across The Spider-Verse 1" className="box-image" src="box_spiderverse1.png"/></a>
            <div className="pinname"><b>Spider-Man Across The Spider-Verse Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y313</div>
        </div></li>  

    <li className="gallerybox">
            <div className="setbox">
            <a title="Star Wars Sith 1" href="/set/Star%20Wars%20Sith%20Mystery%20Series%201">
            <img alt="Star Wars Sith 1" className="box-image" src="box_starwars1.png"/></a>
            <div className="pinname"><b>Star Wars Sith Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y383</div>
        </div></li>  
    <li className="gallerybox">
            <div className="setbox">
            <a title="Magic: The Gathering 1" href="/set/Magic:%20The%20Gathering%20Mystery%20Series%201">
            <img alt="Magic: The Gathering 1" className="box-image" src="box_magic1.png"/></a>
            <div className="pinname"><b>Magic: The Gathering Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y327</div>
        </div></li>  

        <li className="gallerybox">
            <div className="setbox">
            <a title="Teenage Mutant Ninja Turtles 1" href="/set/Teenage%20Mutant%20Ninja%20Turtles%20Mystery%20Series%201">
            <img alt="Teenage Mutant Ninja Turtles 1" className="box-image" src="box_tmnt1.png"/></a>
            <div className="pinname"><b>Teenage Mutant Ninja Turtles Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y243</div>
        </div></li>  
    <li className="gallerybox">
            <div className="setbox">
            <a title="Chainsaw Man 1" href="/set/Chainsaw%20Man%20Mystery%20Series%201">
            <img alt="Chainsaw Man 1" className="box-image" src="box_chainsaw1.png"/></a>
            <div className="pinname"><b>Chainsaw Man Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y80</div>
        </div></li>  

    <li className="gallerybox">
            <div className="setbox">
            <a title="Lilo & Stitch 2" href="/set/Lilo%20and%20Stitch%20Mystery%20Series%202">
            <img alt="Lilo & Stitch 2" className="box-image" src="box_stitch2.png"/></a>
            <div className="pinname"><b>Lilo & Stitch Series 2</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y271</div>
        </div></li>  

    <li className="gallerybox">
            <div className="setbox">
            <a title="One Piece 1" href="/set/One%20Piece%20Mystery%20Series%201">
            <img alt="One Piece 1" className="box-image" src="box_onepiece1.png"/></a>
            <div className="pinname"><b>One Piece Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y284</div>
        </div></li>  

    <li className="gallerybox">
            <div className="setbox">
            <a title="X-Men '97 1" href="/set/X-Men%20'97%20Mystery%20Series%201">
            <img alt="X-Men '97 1" className="box-image" src="box_xmen1.png"/></a>
            <div className="pinname"><b>X-Men '97 Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y229</div>
        </div></li>  

    <li className="gallerybox">
            <div className="setbox">
            <a title="Solo Leveling 1" href="/set/Solo%20Leveling%20Mystery%20Series%201">
            <img alt="Solo Leveling 1" className="box-image" src="box_sololeveling1.png"/></a>
            <div className="pinname"><b>Solo Leveling Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y257</div>
        </div></li>  

    <li className="gallerybox">
            <div className="setbox">
            <a title="Avatar 1" href="/set/Avatar%20The%20Last%20Airbender%20Mystery%20Series%201">
            <img alt="Avatar 1" className="box-image" src="box_avatar1.png"/></a>
            <div className="pinname"><b>Avatar The Last Airbender Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y187</div>
        </div></li>  

    <li className="gallerybox">
            <div className="setbox">
            <a title="SpongeBob 1" href="/set/SpongeBob%20SquarePants%20Mystery%20Series%201">
            <img alt="SpongeBob 1" className="box-image" src="box_spongebob1.png"/></a>
            <div className="pinname"><b>SpongeBob SquarePants Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y215</div>
        </div></li>  

    <li className="gallerybox">
            <div className="setbox">
            <a title="Yu-Gi-Oh 1" href="/set/Yu-Gi-Oh%20Mystery%20Series%201">
            <img alt="Yu-Gi-Oh 1" className="box-image" src="box_yugioh1.png"/></a>
            <div className="pinname"><b>Yu-Gi-Oh Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y159</div>
        </div></li>  

        <li className="gallerybox">
            <div className="setbox">
            <a title="Powerpuff Girls Series 1" href="/set/Powerpuff%20Girls%20Series%201">
            <img alt="Powerpuff Girls Series 1" className="box-image" src="box_ppg1.png"/></a>
            <div className="pinname"><b>Powerpuff Girls Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y201</div>
        </div></li>  

        <li className="gallerybox">
            <div className="setbox">
            <a title="Hello Kitty 3" href="/set/Hello%20Kitty%20Mystery%20Series%203">
            <img alt="Hello Kitty 3" className="box-image" src="box_hk3.png"/></a>
            <div className="pinname"><b>Hello Kitty Series 3</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y70</div>
        </div></li>  

        <li className="gallerybox">
            <div className="setbox">
            <a title="Webtoon 1" href="/set/Webtoon%20Mystery%20Series%201">
            <img alt="Webtoon 1" className="box-image" src="box_webtoon1.png"/></a>
            <div className="pinname"><b>Webtoon Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y145</div>
        </div></li>  
<li className="gallerybox">
            <div className="setbox">
            <a title="Hello Kitty 5" href="/set/Hello%20Kitty%20Mystery%20Series%205">
            <img alt="Hello Kitty 5" className="box-image" src="box_hk5.png"/></a>
            <div className="pinname"><b>Hello Kitty Series 5</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y173</div>
        </div></li>  

        <li className="gallerybox">
            <div className="setbox">
            <a title="Lilo & Stitch 1" href="/set/Lilo%20and%20Stitch%20Mystery%20Series%201">
            <img alt="Lilo & Stitch 1" className="box-image" src="box_stitch1.png"/></a>
            <div className="pinname"><b>Lilo & Stitch Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y110</div>
        </div></li>  

        <li className="gallerybox">
            <div className="setbox">
            <a title="Five Nights at Freddy's" href="/set/Five%20Nights%20at%20Freddy's%20Mystery%20Series%202">
            <img alt="Five Nights at Freddy's" className="box-image" src="box_fnaf2.png"/></a>
            <div className="pinname"><b>Five Nights at Freddy's Series 2</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y130</div>
        </div></li>  

<li className="gallerybox">
            <div className="setbox">
            <a title="Hello Kitty 4" href="/set/Hello%20Kitty%20Mystery%20Series%204">
            <img alt="Hello Kitty 4" className="box-image" src="box_hk4.png"/></a>
            <div className="pinname"><b>Hello Kitty Series 4</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y90</div>
        </div></li>  

        <li className="gallerybox">
            <div className="setbox">
            <a title="Five Nights at Freddy's" href="/set/Five%20Nights%20at%20Freddy's%20Mystery%20Series%201">
            <img alt="Five Nights at Freddy's" className="box-image" src="box_fnaf1.png"/></a>
            <div className="pinname"><b>Five Nights at Freddy's Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y120</div>
        </div></li>  

        <li className="gallerybox">
            <div className="setbox">
            <a title="Minecraft" href="/set/Minecraft%20Mystery%20Series%202">
            <img alt="Minecraft" className="box-image" src="box_mc2.png"/></a>
            <div className="pinname"><b>Minecraft Series 2</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y60</div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Hello Kitty 2" href="/set/Hello%20Kitty%20Mystery%20Series%202">
            <img alt="Hello Kitty 2" className="box-image" src="box_hk2.png"/></a>
            <div className="pinname"><b>Hello Kitty Series 2</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y50</div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Minecraft" href="/set/Minecraft%20Mystery%20Series%201">
            <img alt="Minecraft" className="box-image" src="box_mc1.png"/></a>
            <div className="pinname"><b>Minecraft Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y40</div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Sonic The Hedgehog" href="/set/Sonic%20The%20Hedgehog%20Mystery%20Series%201">
            <img alt="Sonic The Hedgehog" className="box-image" src="box_sh1.png"/></a>
            <div className="pinname"><b>Sonic The Hedgehog Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y30</div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Hello Kitty 1" href="/set/Hello%20Kitty%20Mystery%20Series%201">
            <img alt="Hello Kitty 1" className="box-image" src="box_hk1.png"/></a>
            <div className="pinname"><b>Hello Kitty Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y20</div>
        </div></li>  

        <li className="gallerybox">
            <div className="setbox">
            <a title="Spy X Family 1" href="/set/Spy%20X%20Family%20Mystery%20Series%201">
            <img alt="Spy X Family 1" className="box-image" src="box_sxf1.png"/></a>
            <div className="pinname"><b>Spy X Family Series 1</b><br/>Reward Bonus: 20<br/>In-App Ultra: Y10</div>
        </div></li>  
        </ul>



<div className="home-category">Special Sets</div>
    <ul className="gallery mw-gallery-traditional">
        {/*
    <li className="gallerybox">
            <div className="setbox">
            <a title="Yami Yugi" href="/tags/Yami Yugi">
            <img alt="Yami Yugi" className="box-image" src="box_yamiyugi.png"/></a>
            <div className="pinname"><b>Yami Yugi</b><br/>Set Bonus: 10</div>
        </div></li>  

*/} 
<li className="gallerybox">
            <div className="setbox">
            <a title="Blue-Eyes White Dragon XL" href="/set/Blue-Eyes%20White%20Dragon%20XL">
            <img alt="Blue-Eyes White Dragon XL" className="box-image" src="box_bewd.png"/></a>
            <div className="pinname"><b>Blue-Eyes White Dragon XL</b></div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Megatron XL" href="/set/Megatron%20XL">
            <img alt="Megatron XL" className="box-image" src="box_megatron.png"/></a>
            <div className="pinname"><b>Megatron XL</b></div>
        </div></li>          
        <li className="gallerybox">
            <div className="setbox">
            <a title="Optimus Prime XL" href="/set/Optimus%20Prime%20XL">
            <img alt="Optimus Prime XL" className="box-image" src="box_optimus.png"/></a>
            <div className="pinname"><b>Optimus Prime XL</b></div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Monkey D. Luffy XL" href="/set/Monkey%20D.%20Luffy%20XL">
            <img alt="Monkey D. Luffy XL" className="box-image" src="box_luffy.png"/></a>
            <div className="pinname"><b>Monkey D. Luffy XL</b></div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Brook XL" href="/set/Brook%20XL">
            <img alt="Brook XL" className="box-image" src="box_brook.png"/></a>
            <div className="pinname"><b>Brook XL</b></div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="RX-78-2 XL" href="/set/RX-78-2%20XL">
            <img alt="RX-78-2 XL" className="box-image" src="box_rx78.png"/></a>
            <div className="pinname"><b>RX-78-2 XL</b></div>
        </div></li>  

        <li className="gallerybox">
            <div className="setbox">
            <a title="FiGPiN Quest - Alleria Windrunner" href="/set/FiGPiN%20Quest%20-%20Alleria%20Windrunner">
            <img alt="FiGPiN Quest - Alleria Windrunner" className="box-image" src="box_alleria.png"/></a>
            <div className="pinname"><b>FiGPiN Quest - Alleria Windrunner</b></div>
        </div></li>  

<li className="gallerybox">
            <div className="setbox">
            <a title="Arthas XL" href="/set/Arthas%20XL">
            <img alt="Arthas XL" className="box-image" src="box_arthas.png"/></a>
            <div className="pinname"><b>Arthas XL</b></div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Genie XL" href="/set/Genie%20XL">
            <img alt="Genie XL" className="box-image" src="box_genie.png"/></a>
            <div className="pinname"><b>Genie XL</b></div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Seven Dwarfs XL" href="/set/Seven%20Dwarfs%20XL">
            <img alt="Seven Dwarfs XL" className="box-image" src="box_dwarfs.png"/></a>
            <div className="pinname"><b>Seven Dwarfs XL</b></div>
        </div></li>  
<li className="gallerybox">
            <div className="setbox">
            <a title="FiGPiN Quest - Deadpool & Wolverine" href="/set/FiGPiN%20Quest%20-%20Deadpool%20and%20Wolverine">
            <img alt="FiGPiN Quest - Deadpool & Wolverine" className="box-image" src="box_quest_deadpool.png"/></a>
            <div className="pinname"><b>FiGPiN Quest - Deadpool & Wolverine</b></div>
        </div></li>  

<li className="gallerybox">
            <div className="setbox">
            <a title="Stitch XL" href="/set/Stitch%20XL">
            <img alt="Stitch XL" className="box-image" src="box_stitch.png"/></a>
            <div className="pinname"><b>Stitch XL</b></div>
        </div></li>  

<li className="gallerybox">
            <div className="setbox">
            <a title="Franky XL" href="/set/Franky%20XL">
            <img alt="Franky XL" className="box-image" src="box_franky.png"/></a>
            <div className="pinname"><b>Franky XL</b></div>
        </div></li>  

<li className="gallerybox">
            <div className="setbox">
            <a title="Dragonzord XL" href="/set/Dragonzord%20XL">
            <img alt="Dragonzord XL" className="box-image" src="box_dragonzord.png"/></a>
            <div className="pinname"><b>Dragonzord XL</b></div>
        </div></li>  

<li className="gallerybox">
            <div className="setbox">
            <a title="Jinbe XL" href="/set/Jinbe%20XL">
            <img alt="Jinbe XL" className="box-image" src="box_jinbe.png"/></a>
            <div className="pinname"><b>Jinbe XL</b></div>
        </div></li>  

<li className="gallerybox">
            <div className="setbox">
            <a title="Megazord XL" href="/set/Megazord%20XL">
            <img alt="Megazord XL" className="box-image" src="box_megazord.png"/></a>
            <div className="pinname"><b>Megazord XL</b></div>
        </div></li>  

<li className="gallerybox">
            <div className="setbox">
            <a title="FiGPiN Quest - Naruto" href="/set/FiGPiN%20Quest%20-%20Naruto">
            <img alt="FiGPiN Quest - Naruto" className="box-image" src="box_quest_naruto.png"/></a>
            <div className="pinname"><b>FiGPiN Quest - Naruto</b></div>
        </div></li>  

<li className="gallerybox">
            <div className="setbox">
            <a title="Clip Halloween" href="/set/Clip%20Halloween%20Set">
            <img alt="Clip Halloween" className="box-image" src="box_cliphalloween.png"/></a>
            <div className="pinname"><b>Clip Halloween </b></div>
        </div></li>  


<li className="gallerybox">
            <div className="setbox">
            <a title="eVend SDCC 2023" href="/set/eVend%20SDCC%202023%20Set">
            <img alt="eVend SDCC 2023" className="box-image" src="box_evend_sdcc_2023.png"/></a>
            <div className="pinname"><b>eVend SDCC 2023</b><br/>Reward Bonus: 20<br/>In-App Logo</div>
        </div></li>  


        <li className="gallerybox">
            <div className="setbox">
            <a title="eVend Star Wars" href="/set/eVend%20Star%20Wars%20Set">
            <img alt="eVend Star Wars" className="box-image" src="box_evend_starwars.png"/></a>
            <div className="pinname"><b>eVend Star Wars</b><br/>Reward Bonus: 20<br/>In-App Logo: L94</div>
        </div></li>  
    <li className="gallerybox">
            <div className="setbox">
            <a title="Bounty Hunter" href="/set/Bounty%20Hunter%20Set">
            <img alt="Bounty Hunter" className="box-image" src="box_bounty_hunter.png"/></a>
            <div className="pinname"><b>Bounty Hunter</b><br/>Set Bonus: 10</div>
        </div></li>  
    <li className="gallerybox">
            <div className="setbox">
            <a title="Hello Kitty Halloween" href="/set/Hello%20Kitty%20Halloween%20Set">
            <img alt="Hello Kitty Halloween" className="box-image" src="box_hellokitty_halloween.png"/></a>
            <div className="pinname"><b>Hello Kitty Halloween</b><br/>Reward Bonus: 20<br/>In-App Logo: L78</div>
        </div></li>  
    <li className="gallerybox">
            <div className="setbox">
            <a title="FWAP 2022" href="/set/FWAP%202022%20Set">
            <img alt="FWAP 2022" className="box-image" src="box_fwap22.png"/></a>
            <div className="pinname"><b>FWAP 2022</b><br/>Set Bonus: 10</div>
        </div></li>  
    <li className="gallerybox">
            <div className="setbox">
            <a title="AwesomeFest" href="/set/AwesomeFest%202020%20Set">
            <img alt="AwesomeFest" className="box-image" src="box_awesomefest.png"/></a>
            <div className="pinname"><b>AwesomeFest 2020</b><br/>Set Bonus: 10</div>
        </div></li>  
    <li className="gallerybox">
            <div className="setbox">
            <a title="The Blues Brothers Set" href="/set/The%20Blues%20Brothers%20Set">
            <img alt="The Blues Brothers Set" className="box-image" src="box_bluesbrothers.png"/></a>
            <div className="pinname"><b>The Blues Brothers</b><br/>Set Bonus: 10</div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Holiday Logo 2019 Set" href="/set/Holiday%20Logo%202019%20Set">
            <img alt="Holiday Logo 2019 Set" className="box-image" src="box_holidaylogo.png"/></a>
            <div className="pinname"><b>Holiday Logo 2019</b><br/>Set Bonus: 10</div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Blizzard 30th Anniversary Set" href="/set/Blizzard%2030th%20Anniversary%20Set">
            <img alt="Blizzard 30th Anniversary Set" className="box-image" src="box_blizzard.png"/></a>
            <div className="pinname"><b>Blizzard 30th Anniversary</b><br/>Set Bonus: 10</div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Toy Story 4 Collection" href="/set/Toy%20Story%204%20Set">
            <img alt="Toy Story 4 Collection" className="box-image" src="box_toystory.png"/></a>
            <div className="pinname"><b>Toy Story 4</b><br/>Set Bonus: 10</div>
        </div></li>  
        <li className="gallerybox">
            <div className="setbox">
            <a title="Incredibles 2 Set" href="/set/Incredibles%202%20Set">
            <img alt="Incredibles 2 Set" className="box-image" src="box_incredibles.png"/></a>
            <div className="pinname"><b>Incredibles 2</b><br/>Set Bonus: 10</div>
        </div></li>          
        <li className="gallerybox">
        <div className="setbox">
        <a title="Call of Duty" href="/set/Call%20of%20Duty%204-Pack">
        <img alt="Call of Duty" className="box-image" src="box_callofduty.png"/></a>
        <div className="pinname"><b>Call of Duty</b></div>
    </div></li>
    </ul>
        </div>
    );
}

export default Sets;


